/** @jsx jsx */
import { css, jsx } from 'theme-ui'
import React from 'react'
import { Global } from '@emotion/core'
import PropTypes from 'prop-types'

const Layout = ({ children }) => {

  return (
    <>
      <Global
        styles={css({
          // A Modern CSS Reset - https://dev.to/hankchizljaw/a-modern-css-reset-6p3
          '*,*::before,*::after': { boxSizing: 'border-box' },
          'body,h1,h2,h3,h4,p,ul[class],ol[class],li,input, button, optgroup, select, textarea, figure,figcaption,blockquote,dl,dd': { margin: 0 },
          body: {
            backgroundColor: 'background',
            backgroundImage: 'url("images/bg-blueplaid.jpg")',
            backgroundRepeat: 'repeat-y',
            backgroundSize: ['185%', '100%'],
            backgroundPosition: 'top, center',
            color: 'text',
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            textRendering: 'optimizeLegibility',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          },
          '::selection': {
            backgroundColor: 'text',
            color: 'background',
          },
        })}
      />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout