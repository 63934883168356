/** @jsx jsx */
import { jsx } from 'theme-ui'
import { alpha } from '@theme-ui/color'
import { useState, useEffect } from 'react'
import { Container, Box, Heading, Text, Label, Input, Textarea, Flex, Button, Link } from '@theme-ui/components'
import autosize from "autosize";
import CheckboxTree from '../components/checkbox-tree'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import TeamworkSVG from '../svgs/teamwork'

const ContactForm = () => {
  const [checked, setChecked] = useState([])

  function onCheck(checked) {
    setChecked( checked )
  }

  let textarea = null

  useEffect(() => {
    autosize(textarea)
  }, [])

  return (
      <Container id='contact-form' mt={[ 0, 10, 12 ]}>
        <Box as='form' mt={7} sx={{ maxWidth: '850px' }} 
          name='contact' method='post' data-netlify='true' data-netlify-honeypot='bot-field'
        >
          <Heading as='h2' variant='subHeading' ml={[8, 10, 11, 10, 10]}>What interests you?</Heading>
          <Flex sx={{ 
            mt: [6,8],
            ml: [2, 7, 7, 10],
            mr: [2, 7, 7, 10],
            mb: 13,
            justifyContent: 'center'
          }}>
            <CheckboxTree checked={checked} onCheck={onCheck}/>
          </Flex>
          <Box ml={[7, 7, 7, 10]} mr={[7, 7, 7, 10]}>
            <Input type='hidden' name='form-name' value='contact'/>
            <Label htmlFor='name'>My name is</Label>
            <Input name='name' placeholder='Full Name'/>
            <Text variant='forms.label'>I’m interested in <span sx={{ color: 'white', backgroundColor: alpha('primary', .15) }}>
              { checked.length ? checked.join(', ') : 'Salesforce' }</span> and
            </Text>
            <Textarea ref={ c => (textarea = c) } name='message' placeholder='Message' title='Message' rows='1' defaultValue=''/>
            <Input type='hidden' name='bot-field'/>
            <Label htmlFor='email'>Email me at</Label>
            <Input name='email' placeholder='Email Address'/>
            <Flex sx={{ justifyContent: 'center' }}>
              <Button type='submit' sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover span': {
                  color: 'text',
                  transform: 'translateX(12%)',
                },
                '&:hover svg': {
                  fill: 'white',
                  stroke: 'white',
                  transform: 'translateX(12%)',
                  opacity: 0,
                },
              }}>
                <Text as='span' variant='text.body' sx={{
                  fontSize: [2, 3, 3, 3],
                  mr: 3,
                  fontWeight: 'bold',
                  color: 'primary',
                  transition: 'all 0.25s ease-in-out 0s',
                }}>
                  Submit
                </Text>
                <svg  viewBox="0 0 24 24" sx={{ 
                  width: 5,
                  fill: 'primary',
                  stroke: 'primary',
                  strokeWidth: '2px',
                  transition: 'all 0.25s ease-in-out 0s',
                }}><path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"></path></svg>
              </Button>
            </Flex>
          </Box>
        </Box>
        <Flex sx={{ 
          justifyContent: 'flex-end', 
          alignItems: 'center',
          fontSize: 3,
        }}>
          <Link
              mr={ [8, 12] }
              sx={{ display: ['flex', 'flex', 'none' ] }}
              aria-label="Link to Oliver Sumpton's LinkedIn profile"
              href='#'
              href='https://www.linkedin.com/in/oliversumpton/'
            >
            <FontAwesomeIcon icon={faLinkedin} size='3x'/>
          </Link>
          <TeamworkSVG sxProp={{
            width: ['55%', '45%', '40%', '34%'],
            mr: [1, 2, 0],
          }}/>
        </Flex>
      </Container>
  )
}

export default ContactForm