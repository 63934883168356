/** @jsx jsx */
import { jsx } from 'theme-ui'
import { alpha } from '@theme-ui/color'
import { useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare, faPlusSquare, faMinusSquare, faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight, faChevronDown, faCloud, faFolder } from '@fortawesome/free-solid-svg-icons'

const mdTree =
`- [Sales Cloud](Sales Cloud)
    - [Automation](Sales Automation)
        - [Document Generation](Document Generation for Sales)
        - [Guided Selling](Guided Selling)
        - [Sales Console](Sales Console)
            - [Lightning Dialer](Lightning Dialer for Sales Console)
            - [Macros](Macros for Sales Console)
        - [New Leads](New Leads)
            - [Lightning Data](Lightning Data)
    - [Lightning](Lightning for Sales)
    - [Email](Email Integration for Sales)
        - [Outlook Integration](Outlook Integration for Sales)
        - [Gmail Integration](Gmail Integration for Sales)
        - [Salesforce Inbox](Salesforce Inbox)
            - [Inbox Mobile](Inbox Mobile)
            - [Einstein Activity Capture](Einstein Activity Capture)
            - [Insert Availability](Insert Availability)
    - [Mobile](Salesforce Mobile for Sales)
    - [Analytics](Sales Analytics)
        - [Reports & Dashboards](Reports and Dashboards for Sales)
        - [Tableau](Tableau for Sales)
        - [Einstein Analytics](Einstein Analytics for Sales)
        - [Map Visualization](Map Visualization for Sales)
    - [CPQ & Billing](CPQ & Billing)
    - [Campaigns](Campaign Management)
    - [Forecasts](Collaborative Forecasts)
    - [Territory Management](Territory Management)
    - [Sales Cloud Einstein](Sales Cloud Einstein)
        - [Lead & Opportunity Scoring](Lead and Opportunity Scoring)
        - [Account Insights](Account Insights)
- [Service Cloud](Service Cloud)
    - [Automation](Automation for Service)
        - [Service Console](Service Console)
            - [Lightning Dialer](Lightning Dialer for Service)
            - [Macros](Macros for Service)
        - [Lightning Flow for Service](Lightning Flow for Service)
            - [Flow Builder](Flow Builder for Service)
            - [Next Best Action](Einstein Next Best Action)
    - [Lightning](Lightning for Service)
    - [Email](Email Integration for Service)
        - [Outlook Integration](Outlook Integration for Service)
        - [Gmail Integration]( for Service)
    - [Mobile](Salesforce Mobile for Service)
    - [Analytics](Analytics for Service)
        - [Reports & Dashboards](Reports and Dashboards for Service)
        - [Tableau](Tableau for Service)
        - [Einstein Analytics](Einstein Analytics for Service)
        - [Map Visualization](Map Visualization for Service)
    - [Case Management](Case Management)
        - [Case Routing](Case Routing)
            - [Case Queues](Case Queues)
            - [Omni-Channel Routing](Omni-Channel Routing)
        - [Case Teams](Case Teams)
        - [Service Level Agreements](Service Level Agreements)
            - [Entitlements](Entitlements)
            - [Case Milestones](Case Milestones)
            - [Service Contracts](Service Contracts)
        - [Asset Tracking](Asset Tracking)
    - [Service Channels](Service Channels)
        - [Web to Case](Web to Case)
        - [Email to Case](Email to Case)
        - [Phone](Phone)
            - [Open CTI](Open CTI)
        - [Web Chat](Web Chat)
            - [Einstein Bots](Einstein Bots)
        - [Social Media](Social Media)
        - [Self-Service Community](Self-Service Community for Service)
    - [Knowledge](Self-Service Community)
    - [Field Service Lightning](Field Service Lightning)
- [Custom App Cloud](Custom App Cloud)
    - [Process Automation](Process Automation for Custom Apps)
    - [Document Generation](Document Generation for Custom Apps)
    - [Reports & Dashboards](Reports and Dashboards for Custom Apps)
    - [Mobile](Salesforce Mobile for Custom Apps)
    - [Heroku](Heroku)
- [Community Cloud](Community Cloud)
    - [Customer Communities](Customer Communities)
    - [Partner Communities](Partner Communities)
    - [Employee Communities](Employee Communities)
- [Marketing Cloud](Marketing Cloud)
    - [Pardot](Pardot)
    - [Social Studio](Social Studio)
    - [Email Studio](Email Studio)
    - [Journey Builder](Journey Builder)
    - [Interaction Studio](Interaction Studio)
    - [Audience Studio](Audience Studio)
    - [Advertising Studio](Advertising Studio)
    - [Mobile Studio](Mobile Studio)
    - [Data Studio](Data Studio)
    - [Datorama](Datorama)
- [Other Clouds](Other Clouds)
    - [Integration Cloud](Integration Cloud)
    - [Commerce Cloud](Commerce Cloud)
    - [Manufacturing Cloud](Manufacturing Cloud)
    - [Financial Services Cloud](Financial Services Cloud)
    - [Health Cloud](Health Cloud)
    - [Consumer Goods Cloud](Consumer Goods Cloud)
    - [Government Cloud](Government Cloud)
    - [Philanthropy Cloud](Philanthropy Cloud)
    - [Non-Profit Cloud](Non-Profit Cloud)`

function doParse(mdTree) {
  // https://github.com/brucejcw/md-list-tree-parser
  var jsonTree = []
  var lines = mdTree.split('\n')
  // var regex = /^(\s*)-\s(.*)/
  var regex = /^(\s*)-\s\[(.*)\]\s*(\((.*)\))?/
  // https://regexper.com/
  lines.forEach(function(line, i) {
    var matchs = line.match(regex)
    if (matchs) {
      var level = matchs[1].length / 4
      var label = matchs[2]
      var value = matchs[4]
      var node = new Node(label, value)

      if (level === 0) {
        jsonTree.push(node)
      } else {
        var p = getParentNode(level, jsonTree)
        p.children.push(node)
      }
    }
  })
  return jsonTree
}

function getParentNode(level, jsonTree) {
  var i = 0
  var node = jsonTree[jsonTree.length -1]
  while(i < level - 1) {
    var children = node.children
    node = children[children.length - 1]
    i++
  }

  if (!node.children) {
    node.children = []
  }
  return node
}

function Node(label, value) {
  this.value = value
  this.label = label
}

const rctIconColor = 'primary'
const retLabelHover = alpha('primary', .1)
const rctLabelActive = alpha('primary', .15)
const rctClickableHover = alpha('primary', .1)
const rctClickableFocus = alpha('primary', .2)

const styles = {
  '& .react-checkbox-tree': {
    // minWidth: [0, 0, '340px'],
    minWidth: [0, '400px', '440px'],
    display: 'flex',
    flexDirection: 'row-reverse',
    fontSize: ['18px', 2, '1.375rem'],
    lineHeight: ['1.75', '1.7', 'relaxed'],

    '& > ol': {
      flex: '1 1 auto',
    },
  
    '& ol': {
      margin: '0',
      paddingLeft: '0',
      listStyleType: 'none',
  
      '& ol': {
        paddingLeft: '24px',
      },
    },
  
    '& button': {
      lineHeight: 'normal',
      color: 'inherit',
  
      '&:focus': {
        outline: 'none',
      },
  
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
  
    '& .rct-bare-label': {
      cursor: 'default',
    },
  
    '& label': {
      marginBottom: '0',
      cursor: 'pointer',
  
      '&:hover': {
        background: retLabelHover,
      },
  
      '&:active': {
        background: rctLabelActive,
      },
    },
  
    '&:not(.rct-native-display) input': {
      display: 'none',
    },
  
    '&.rct-native-display input': {
      margin: '0 5px',
    },
  
    '& .rct-icon': {
      display: 'inline-block',
      textAlign: 'center',
      textRendering: 'auto',
      // fontFamily: '"Font Awesome 5 Free", "FontAwesome", sans-serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontVariant: 'normal',
    },
  },
  
  '& .rct-disabled': {
    '& > .rct-text > label': {
      opacity: '.75',
      cursor: 'not-allowed',
  
      '&:hover': {
        background: 'transparent',
      },
  
      '&:active': {
        background: 'transparent',
      },
    },
  },
  
  '& .rct-text': {
    display: 'flex',
    alignItems: 'center',
  },
  
  '& .rct-options': {
    flex: '0 0 auto',
    marginLeft: '.5rem',
    textAlign: 'right',
  },
  
  '& .rct-option': {
    opacity: '.75',
    border: '0',
    background: 'none',
    cursor: 'pointer',
    padding: '0 4px',
    fontSize: 4,
  
    '&:hover': {
      opacity: '1',
    },
  
    '+ .rct-option': {
      marginLeft: '2px',
    },
  },
  
  '& .rct-collapse, .rct-checkbox, .rct-node-icon': {
    padding: ['0 2px', '0 5px'],
  
    '& *': {
      display: 'inline-block',
      margin: '0',
      width: 5,
    },
  },
  
  '& .rct-collapse': {
    border: '0',
    background: 'none',
    lineHeight: 'normal',
    color: 'inherit',
    fontSize: '18px',
  
    '&.rct-collapse-btn': {
      cursor: 'pointer',
    },
  
    '& > .rct-icon-expand-close': {
      opacity: '.5',
  
      '&:hover': {
        opacity: '1',
      },
    },
  },
  
  '& .rct-native-display .rct-checkbox': {
      display: 'none',
  },
  
  '& .rct-node-clickable': {
    cursor: 'pointer',
  
    '&:hover': {
      background: rctClickableHover,
    },
  
    '&:focus': {
      outline: '0',
      background: rctClickableFocus,
    },
  },
  
  '& .rct-node-icon': {
    color: rctIconColor,
  },
  
  '& .rct-title': {
    padding: '0 5px',
  },
}

const CheckboxTreeFunction = ({ checked, onCheck }) => {
  const [expanded, setExpanded] = useState([])
  const [clicked, setClicked] = useState({})

  function onExpand(expanded) {
    setExpanded( expanded )
  }
  function onClick(clicked) {
    setClicked({ clicked })
  }

  return (
    <div sx={styles}>
      <CheckboxTree
        name='interests'
        nodes={doParse(mdTree)}
        noCascade
        // checkModel='all' 
        // optimisticToggle={false}
        // showExpandAll
        checked={checked}
        onCheck={onCheck}
        expanded={expanded}
        onExpand={onExpand}
        expandOnClick
        onClick={onClick}
        iconsClass="fa5"
        icons={{
          check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
          uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
          halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
          expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
          expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
          expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
          collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
          parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
          parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
          leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faCloud} />
        }}
      />
    </div>
  )
}

export default CheckboxTreeFunction